
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Item } from '@/common/models/Item.model';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import { clientModule } from '@/modules/client/client.vuex-module';

@Options({
	name: 'ConfirmationView',
	components: {},
})
export default class ConfirmationView extends Vue {
	clientModule = clientModule;
	get item(): Item | null {
		return orderModule.openItem;
	}
	orderModule = orderModule;
	get activeOrder(): ActiveOrder | null {
		return orderModule._activeOrder;
	}
	get qrImage(): string {
		const kioskApiUrl = (window as any).config.KioskUrl ?? window.location.origin;
		let waiver = `${kioskApiUrl}/checkin/form/${this.activeOrder?.orderKey ?? ''}/${clientModule.location?.LocationCode ?? ''}/false`;
		
		const qrCodeSize = 300;
		var payload = escape(waiver);
		return `${kioskApiUrl}/api/qr?s=${qrCodeSize}&p=${payload}`;
	}
	done() {
		let wk = (window as any).webkit;
		if (wk && wk.messageHandlers && wk.messageHandlers.onClose) {
			wk.messageHandlers.onClose.postMessage({
				message: 'Hey foo',
			});
		}
	}
	mounted(): void {
		if (!orderModule._activeOrder) {
			this.$router.push(`/portal/${clientModule.clientLocationKey}`);
		}
	}
	getImage(): string {
		if (!this.item) {
			return '';
		}
		return this.item?.PhotoDomain + 'w_800,h_400,c_fill' + '/v' + this.item?.PhotoVersion + this.item?.PhotoFolder + '/original';
	}
	getOptions(): string[] {
		var res: string[] = [];
		this.item?.Activities.forEach((entity) => {
			if (this.item?.PackageKey) {
				res.push(entity.Name);
			}
			if (entity.Difficulty) {
				res.push(`*${entity.Difficulty}`);
			}
			if (entity.Duration) {
				res.push(`*${entity.Duration}`);
			}
		});
		return res;
	}
	completeOnDevice() {
		let wk = (window as any).webkit;
		console.log(wk, wk.messageHandlers, wk.messageHandlers.completeOnDevice);
		if (wk && wk.messageHandlers && wk.messageHandlers.completeOnDevice) {
			wk.messageHandlers.completeOnDevice.postMessage({
				orderKey: this.activeOrder?.orderKey,
			});
		}
	}
}
